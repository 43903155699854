import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './MarketingCampaignsAndSystems.module.css';
import Container from '../../../blocks/Container';
import Title from '../../../elements/Title';
import MoreGoodness from '../elements/MoreGoodness';
import AgreeButton from '../elements/AgreeButton';

const MarketingCampaignsAndSystems = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/campaigns-and-systems.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              otherItems {
                color
                title
                text
              }
            }
          }
        }
      }
    }
  `);

  const { otherItems, title } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.marketingCampaignsAndSystems}>
      <Container>
        <Container type="mobile" className="d-flex flex-column align-items-center">
          <Title type="small" className={styles.title}>{title}</Title>
          <MoreGoodness items={otherItems} withoutTitle={true} displayOnMobile={true} />
          <AgreeButton className={styles.cta}>Sign up for $79/MO</AgreeButton>
        </Container>
      </Container>
    </div>
  )
}

export default MarketingCampaignsAndSystems;
