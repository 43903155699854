import React from 'react';
import PropTypes from 'prop-types';

import styles from './Title.module.css';

import { capitalize } from '../../../../../utils';

const Title = ({ type, children, className }) => (
  <div
    className={`${styles.title} ${className} ${
      styles[`title${capitalize(type)}`]
    }`}
  >
    {children}
  </div>
);

Title.defaultProps = {
  className: '',
  type: 'normal',
};

Title.propsTypes = {
  type: PropTypes.oneOf([
    'normal',
    'small',
    'italic',
    'medium',
    'underlined',
    'big',
    'bigxl',
    'bigger',
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Title;
