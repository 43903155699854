import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './AgreeLabel.module.css';
import JoinModal from '../JoinModal';

const AgreeLabel = ({ className, children }) => {
  const [opened, setOpened] = useState(false);

  const toggle = () => setOpened(!opened);

  return (
    <div className={`${styles.container} ${className}`}>
      <div
        className={styles.agreeLabel}
        onClick={toggle}
        onKeyDown={toggle}
        role="button"
        tabIndex={0}
        dangerouslySetInnerHTML={{ __html: children }}
      />
      <div className={styles.agreeDescription}>
        This price includes access to all existing Deadline Funnel features on the Create plan + instant access to Portal!
      </div>
      <JoinModal opened={opened} onClose={toggle} annual={true} />
    </div>
  );
}

AgreeLabel.defaultProps = {
  className: ''
}

AgreeLabel.propsTypes = {
  children: PropTypes.node.isRequired
};

export default AgreeLabel;
