import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PortalLaunchHeader.module.css';
import Container from '../../../blocks/Container';
import Title from '../../../elements/Title';
import Image from '../../../elements/Image';
import FullWidthRow from '../../../blocks/FullWidthRow';
import WistiaEmbed from '../../../elements/WistiaEmbed';
import AgreeButton from '../elements/AgreeButton';
import AgreeLabel from '../elements/AgreeLabel';

const PortalLaunchHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal-launch-header.md/" } }) {
        edges {
          node {
            frontmatter {
              introText
              title
              subtitle
              videoHash
            }
          }
        }
      }
    }
  `);

  const { introText, title, subtitle, videoHash } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.portalLaunchHeader}>
      <Container>
        <Container type="mobile">
          <FullWidthRow>
            <div className={styles.contentContainer}>
              <div className={styles.introBlock}>
                <Image name="portal-launch/ta_da" className={styles.introBlock__img}/>
                {introText}
              </div>
              <Title className={styles.title}>{title}</Title>
              <Title className={styles.subtitle}>
                <span dangerouslySetInnerHTML={{ __html: subtitle }}/>
              </Title>
              <div className={styles.video}>
                <WistiaEmbed type="inline" hashedId={videoHash}/>
                <Image name="portal-launch/header_women_left" className={styles.womanImg}/>
              </div>
              <AgreeButton className={styles.agreeButton}>Sign up for $79/MO</AgreeButton>
              <AgreeLabel>Or sign up for the annual plan ($790/yr)</AgreeLabel>
              <Image name="portal-launch/header_desktop_confetti" className={styles.confettiImgDesktop}/>
              <Image name="portal-launch/another_confetti_img" className={styles.anotherConfettiImg}/>
            </div>
          </FullWidthRow>
        </Container>
      </Container>
    </div>
  )
}

export default PortalLaunchHeader;
