import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './JackNote.module.css';
import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import Text from '../../../elements/Text';
import Title from '../../stealth-selling/elements/Title';
import WistiaEmbed from '../../../elements/WistiaEmbed';

const JackNote = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/jack-note.md/" } }
      ) {
        edges {
          node {
            frontmatter {
              buttonText
              annualText
              introTitle
              introDescriptions
              audienceTitle
              audienceContents {
                description
                features
              }
              whatsNewTitle
              whatsNewDescription
              whatsNewFeatures {
                title
                description
              }
              compareTitle
              compareTopDescription
              portalDescription
              compareTableHeader {
                label
                width
              }
              compareTableBody {
                label
                current
                withDF
              }
              trainingTitle
              trainingTopDescription
              trainingBottomDescription
              trainingBottomDescriptionOne
              trainingBottomDescriptionTwo
              trainingBottomDescriptionThree
              trainingWeeks {
                title
                image
                color
                date
                description
              }
              noteTitle
              noteDescriptions
            }
          }
        }
      }
    }
  `);

  const {
    introTitle,
    introDescriptions,
    audienceTitle,
    audienceContents,
    whatsNewTitle,
    whatsNewDescription,
    whatsNewFeatures,
    compareTitle,
    compareTopDescription,
    compareTableHeader,
    compareTableBody,
    trainingTitle,
    trainingTopDescription,
    trainingBottomDescription,
    trainingBottomDescriptionOne,
    trainingBottomDescriptionTwo,
    trainingBottomDescriptionThree,
    trainingWeeks,
    noteTitle,
    noteDescriptions,
    portalDescription,
  } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.jackNote}>
      <Container type="big">
        <Container
          className="d-flex flex-column align-items-center"
        >
          <div className={styles.noteContainer}>
            <Image
              name="portal-launch/note_confetti_right"
              className={styles.noteContainer__confettiTopRight}
            />
            <Image
              name="portal-launch/note-confetti_top_left"
              className={styles.noteContainer__confettiTopLeft}
            />
            <Image
              name="portal-launch/note-confetti_middle_right"
              className={styles.noteContainer__confettiMiddleRight}
            />
            <Image
              name="portal-launch/note_man"
              className={styles.noteContainer__man}
            />
            <div className={styles.noteWindow}>
              <div className={styles.noteWindow__content}>
                <div className={styles.noteWindowTitle}>{introTitle}</div>
                {introDescriptions.map((description, index) => (
                  <div
                    className={styles.noteWindowDescription}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                ))}
                <div className={`${styles.noteWindowTitle} mt-3 mt-sm-5`}>
                  {audienceTitle}
                </div>
                {audienceContents.map((content) => {
                  return (
                    <div key={content.description}>
                      <div className={styles.noteWindowDescription}>
                        {content.description}
                      </div>
                      {content.features &&
                        content.features.length > 0 &&
                        content.features.map((feature, index) => (
                          <div
                            className={`${styles.noteWindowDescription} ${
                              index !== 0 ? 'my-0' : ''
                            }`}
                            key={index}
                          >
                            <span className={styles.bullet}>·</span>
                            <span
                              dangerouslySetInnerHTML={{ __html: feature }}
                            ></span>
                          </div>
                        ))}
                    </div>
                  );
                })}
                <div className={`${styles.noteWindowTitle} mt-3 mt-sm-5`}>
                  {compareTitle}
                </div>
                <div
                  className={`${styles.noteWindowDescription}`}
                  dangerouslySetInnerHTML={{
                    __html: portalDescription,
                  }}
                />
                <div className={styles.whatIsPortal}>
                  <Image
                          name="portal-launch/what-is-portal"
                          className={styles.whatIsPortal__img}
                        />
                </div>
                <div
                  className={`${styles.noteWindowDescription}`}
                  dangerouslySetInnerHTML={{
                    __html: compareTopDescription,
                  }}
                />
                <div className={styles.compareTableContainer}>
                  <table>
                    <tr>
                      {compareTableHeader?.map((headerItem) => (
                        <th width={headerItem.width} key={headerItem.label}>{headerItem.label}</th>
                      ))}
                    </tr>
                    <tbody>
                      {compareTableBody?.map((row) => (
                        <tr key={row.label}>
                          <td>{row.label}</td>
                          <td>
                            <Image
                              name={row.current ? 'checked' : 'unchecked'}
                              className={styles.compareTableIcon}
                            />
                          </td>
                          <td>
                            <Image
                              name={row.withDF ? 'checked' : 'unchecked'}
                              className={styles.compareTableIcon}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div id="whats-new" className={`${styles.noteWindowTitle} mt-3 mt-sm-5`}>
                  {whatsNewTitle}
                </div>
                <div
                  className={`${styles.noteWindowDescription} `}
                  dangerouslySetInnerHTML={{ __html: whatsNewDescription }}
                ></div>
                <div className={styles.featuresVideo}>
                  <WistiaEmbed type="inline" hashedId="5fdei0ya6x"/>
                </div>
                {whatsNewFeatures?.map((feature, index) => (
                  <div key={index} className={styles.feature}>
                    <div className={styles.introBlock}>
                      <Image
                        name="portal-launch/ta_da"
                        className={styles.introBlock__img}
                      />
                      {feature.title}
                    </div>
                    <div
                      className={`${styles.noteWindowDescription} px-1 px-sm-3`}
                      dangerouslySetInnerHTML={{ __html: feature.description }}
                    ></div>
                  </div>
                ))}
                <div className={styles.liveTraningTitleContainer}>
                  <Image
                    name="portal-launch/note_confetti_left"
                    className={styles.noteContainer__confettiLeft}
                  />
                  <div className={styles.newBadge}>new!</div>
                  <div className={`${styles.noteWindowTitle} mb-0`}>
                    {trainingTitle}
                  </div>
                </div>
                <div className={styles.commonDescription}>
                  {trainingTopDescription}
                </div>
                <div className="row mt-3 mt-sm-5">
                  {trainingWeeks.map((weekItem) => (
                    <div className={`${styles.traningCard} col-xl-6 col-lg-6 col-12 mb-3 mb-sm-5`} key={weekItem.title}>
                      <div
                        className={styles.liveTraningWeek}
                        style={{ borderColor: weekItem.color }}
                      >
                        <div
                          className={styles.liveTraningWeekHeader}
                          style={{ backgroundColor: weekItem.color }}
                        >
                          <Image
                            name={weekItem.image}
                            className={styles.weekHeaderImage}
                          />
                          <div className={styles.weekOverview}>
                            <Text className={`${styles.weekOverviewTitle}`}>
                              {weekItem.title}
                            </Text>
                            <Text className={`${styles.weekOverviewStateDate}`}>
                              {weekItem.date}
                            </Text>
                          </div>
                        </div>
                        <div className={styles.liveTraningWeekContent}>
                          <div
                            className={`${styles.weekContentTitle} mb-3`}
                            style={{ color: weekItem.color }}
                          >
                            Details:
                          </div>
                          <Text className={`${styles.weekContentDescription}`}>
                            {weekItem.description}
                          </Text>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={styles.commonDescription}>
                  {trainingBottomDescription}
                </div>
                <div className={styles.commonDescription}>
                  {trainingBottomDescriptionOne}
                </div>
                <div className={styles.commonDescription}>
                  {trainingBottomDescriptionTwo}
                </div>
                <div className={styles.commonDescription}>
                  {trainingBottomDescriptionThree}
                </div>
                <Title type="underlined" className="mt-3 mt-sm-5 position-relative">
                  <Image
                    name="portal-launch/note_confetti_right"
                    className={styles.noteContainer__confettiBottomRight}
                  />
                  <span className={styles.line}>{noteTitle}</span>
                </Title>
                {noteDescriptions?.map((description, index) => (
                  <div
                    className={`${styles.commonDescription} mt-3 mt-sm-5`}
                    key={index}
                  >
                    {description}
                  </div>
                ))}
              </div>
              <Image
                name="portal-launch/note_sign"
                className={styles.noteWindow__sign}
              />
              <div className={styles.noteWindow__bottomArrow}>
                <Image name="portal-launch/green-arrow" />
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default JackNote;
