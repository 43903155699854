import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import PortalLaunchHeader from '../components/slides/portal-launch/PortalLaunchHeader';
import JackNote from '../components/slides/portal-launch/JackNote';
import PortalEditor from '../components/slides/portal-launch/PortalEditor';
import PortalOnYourSite from '../components/slides/portal-launch/PortalOnYourSite';
import MarketingCampaignsAndSystems from '../components/slides/portal-launch/MarketingCampaignsAndSystems';
import FrequentlyAskedQuestions from '../components/blocks/FrequentlyAskedQuestions';
import { graphql, useStaticQuery } from 'gatsby';

const PortalLaunch = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal-launch/FAQ.md/" } }) {
        edges {
          node {
            frontmatter {
              faq {
                question
                mobileBig
                answer
              }
            }
          }
        }
      }
    }
  `);

  const { faq } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <Layout>
      <SEO title="Portal Launch | Deadline Funnel" meta={[{
          name: 'robots',
          content: 'noindex',
        }]} />
      <PortalLaunchHeader />
      <JackNote />
      <PortalEditor />
      <PortalOnYourSite />
      <MarketingCampaignsAndSystems />
      <FrequentlyAskedQuestions faq={faq} />
    </Layout>
  );
}

export default PortalLaunch;
