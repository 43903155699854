import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './AgreeButton.module.css';
import JoinModal from '../JoinModal';

const AgreeButton = ({ children, className }) => {
  const [opened, setOpened] = useState(false);

  const toggle = () => setOpened(!opened);

  return (
    <Fragment>
      <div className={`${styles.agreeButton} ${className}`} onClick={toggle} onKeyDown={toggle} role="button" tabIndex={0}>
        {children}
      </div>
      <JoinModal opened={opened} onClose={toggle} annual={false} />
    </Fragment>
  );
}

AgreeButton.defaultProps = {
  className: ''
}

AgreeButton.propsTypes = {
  children: PropTypes.node.isRequired
};

export default AgreeButton;
