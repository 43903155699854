import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'reactstrap';

import styles from './JoinModal.module.css';
import Text from '../../../../elements/Text';
import Button from '../../../../elements/Button';
import CloseModalImg from '../../../../../images/svg/modal_close.svg';

const JoinModal = ({ annual, onClose, opened, className }) => (
  <Modal
    isOpen={opened}
    className={`${styles.modal} ${className}`}
    contentClassName={styles.modalContent}
    backdropClassName={styles.modalBackdrop}
    centered={true}
    toggle={onClose}
  >
    <div onClick={() => onClose()} onKeyDown={() => onClose()} role="button" tabIndex={0}>
      <CloseModalImg className={styles.modalClose} />
    </div>
    <div className={styles.modalContent__title}>Create an Account</div>
    <Text className={`${styles.modalContent__text} text-center mb-4`}>
      Click here to sign up for a new Deadline Funnel + Portal account!
    </Text>
    <Button
      type="action"
      className={styles.modalContent__button}
      href={`https://www.deadlinefunnel.com/special/portal-launch-${annual ? 'annual' : 'monthly'}`}
      target="_blank"
    >
      Create Account
    </Button>
    <div className={styles.modalContent__title}>Upgrade Your Account</div>
    <Text className={`${styles.modalContent__text} text-center mb-4`}>
      Click here to upgrade your existing Deadline Funnel account to get access to Portal!
    </Text>
    <Button
      type="action"
      className={styles.modalContent__button} href={"https://app.deadlinefunnel.com/upgrade/portal"}
      target="_blank"
    >
      Upgrade Account
    </Button>
  </Modal>
);


JoinModal.defaultProps = {
  className: ''
}

JoinModal.propsTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  annual: PropTypes.bool.isRequired
};

export default JoinModal;
